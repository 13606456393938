import { useQuery } from 'react-query';

import Api from '../../api/api';

export type ReportedCommentsDto = {
  page?: number;
  limit?: number;
  sort?: string;
  search?: string;
};

const reportedComments = async (params: ReportedCommentsDto) => {
  const [response, bodyOrError] = await Api.get(`comments/reported`, params);

  if (!response?.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

const useGetAllReportedComments = (params: ReportedCommentsDto) => {
  return useQuery({
    queryKey: ['reportedComments', params],
    queryFn: () => reportedComments(params),
    initialData: { total: 0, data: [], page: 0, limit: 0 },
  });
};

export default useGetAllReportedComments;

import { useQuery } from 'react-query';

import Api from '../../api/api';

export const getReportedCommentById = async (reportedCommentId: string) => {
  const [response, bodyOrError] = await Api.get(`comments/${reportedCommentId}/reported`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useGetReportedCommentById = (reportedCommentId: string) =>
  useQuery(['getReportedCommentById', reportedCommentId], () => getReportedCommentById(reportedCommentId), {
    enabled: reportedCommentId !== 'new' || !!reportedCommentId,
  });

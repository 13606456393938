import { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableSortLabel,
  Box,
  styled,
  Button,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { CreateButton } from '../../../components/buttons';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { SearchField } from '../../../components/inputs';
import { Pagination } from '../../../components/paginations';
import { AlertSnackbarContext } from '../../../components/snackbars';
import { Spinner } from '../../../components/spinners';
import { Defaults } from '../../../constants';
import { useDeleteCategory, useGetCategoriesByPage, useGetCategoriesCount, useUpdateCategory } from '../../../lib/category/hooks';
import { SortDirection } from '../../../lib/util/helpers';

const CategoryList = () => {
  const navigate = useNavigate();
  const updateCategory = useUpdateCategory();
  const handleFeaturedCategory = () => navigate(`/cms/categories/featured`);
  const handleAddCategory = () => navigate(`/cms/categories/create`);
  const handleEditCategory = (categoryId: number) => navigate(`/cms/categories/${categoryId}`);
  const deleteCategory = useDeleteCategory();

  const [optionsUpdated, setOptionsUpdated] = useState(true);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    search: StringParam,
    sort: StringParam,
  });
  const { page, search, sort } = query;
  const searchString = search && { search };
  const debouncedSearchFn = useDebouncedCallback((value: string) => {
    setQuery({ search: value, page: 0 });
  }, Defaults.SEARCH_DEBOUNCE_MS);

  const { data, isLoading, error } = useGetCategoriesByPage({
    page: Number(page) || 0,
    sort: String(sort || ''),
    ...searchString,
  });
  const { data: countData, error: countError, isLoading: isLoadingCount } = useGetCategoriesCount({ ...searchString });
  const [, setAlertSnackbar] = useContext(AlertSnackbarContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    setSubmitting(deleteCategory.isLoading);

    let message = '';
    let severity: 'error' | 'success' | 'info' | 'warning' | undefined = 'success';

    if (!deleteCategory.isLoading) {
      if (deleteCategory.error) {
        message = deleteCategory.error.message;
      } else if (deleteCategory.data) {
        if (isDeleted) {
          message = `${deleteCategory?.data?.title} deleted successfully.`;
        } else {
          message = `${deleteCategory?.data?.title} undelete successfully.`;
        }
      }
    }

    if (deleteCategory.error) {
      severity = 'error';
    }

    if (message !== '') {
      if (severity === 'success') {
        navigate('/cms/categories');
      }
      setAlertSnackbar({ message, severity });
    }
  }, [navigate, isDeleted, deleteCategory.data, deleteCategory.error, deleteCategory.isLoading, setAlertSnackbar]);

  useEffect(() => {
    if (!optionsUpdated) {
      return;
    }
    setOptionsUpdated(false);
  }, [optionsUpdated, page, sort, search]);

  useEffect(() => {
    setOptionsUpdated(true);
  }, [setOptionsUpdated, page, sort, search]);

  const setNewSort = (column: string, direction: SortDirection) => {
    const newSort = direction ? `${column} ${direction}` : undefined;
    setQuery({ sort: newSort });
  };

  const setNewPage = (page: number) => setQuery({ page: page - 1 });

  const sortToObj = (sortStr: string | undefined) => {
    const initialValues = { column: '', direction: undefined };
    if (typeof sortStr !== 'string') {
      return initialValues;
    }
    const [column, direction] = sortStr.split(' ');
    if (typeof column !== 'string' || !['asc', 'desc', undefined].includes(direction)) {
      return initialValues;
    }
    return { ...initialValues, column, direction: direction as SortDirection };
  };

  const sortObj = sortToObj(sort ?? '');

  const columns = [
    {
      text: 'Name',
      name: 'category.title',
    },
    {
      text: '# of topics',
      name: 'category_topic_count',
    },
    {
      text: '# of comments',
      name: 'category_post_comment_count',
    },
    {
      text: '# of views',
      name: 'category_post_view_count',
    },
    {
      text: 'Featured',
      name: 'category.featured',
    },
    {
      text: 'Actions',
      name: '',
    },
  ];

  const handleDelete = async (item: any) => {
    if (item.deleted) {
      // Undelete category and set category.deleted to null
      updateCategory.mutate({ categoryId: item.id, params: { published: item.published ? true : false, featured: false, undelete: true } });
      setSubmitting(true);
      setIsDeleted(false);
    } else {
      // Delete category
      deleteCategory.mutate(item.id);
      setSubmitting(true);
      setIsDeleted(true);
    }
  };

  return (
    <Container>
      <FullWidthBar>
        <Typography variant="h5" sx={{ marginRight: '20px' }}>
          Categories
        </Typography>
        <CreateButton variant="contained" onClick={handleFeaturedCategory}>
          Featured Category
        </CreateButton>
        <StyledSearchField
          searchString={search ?? ''}
          onSearch={(search: string) => {
            debouncedSearchFn(search);
          }}
        />
        <CreateButton variant="contained" onClick={handleAddCategory}>
          Add New Category
        </CreateButton>
      </FullWidthBar>

      <TableContainer sx={{ backgroundColor: 'white' }}>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <PointerTableRow>
              {columns.map((column) => (
                <TableCell key={column.name}>
                  <TableSortLabel
                    active={sortObj.column === column.name}
                    direction={sortObj.direction}
                    onClick={() => {
                      const isAsc = sortObj.column === column.name && sortObj.direction === 'asc';
                      setNewSort(column.name, isAsc ? 'desc' : 'asc');
                    }}
                    component="a"
                  >
                    {column.text}
                    {sortObj.column === column.name ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sortObj.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </PointerTableRow>
          </TableHead>
          <TableBody>
            {(isLoading || isLoadingCount) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Spinner />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              !error &&
              data &&
              data.map((row: any, index: number) => {
                return (
                  <StyledTableRow key={index} className={row.deleted ? 'deleted-row' : 'row'}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.topicCount}</TableCell>
                    <TableCell>{row.postCommentCount}</TableCell>
                    <TableCell>{row.postViewCount}</TableCell>
                    <TableCell>{row.featured ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      {!row.deleted ? (
                        <>
                          <Button onClick={() => handleEditCategory(row.id)} type="button" style={{ textTransform: 'capitalize' }}>
                            Edit
                          </Button>

                          <ConfirmDialog
                            trigger={(open) => (
                              <Button onClick={open} type="button" style={{ textTransform: 'capitalize' }}>
                                Delete
                              </Button>
                            )}
                            title="Delete Category"
                            description="Are you sure you want to delete this category?"
                            cancelBtnText={'Cancel'}
                            submitBtnText={'Submit'}
                            onConfirm={async (close) => {
                              await handleDelete(row);
                              close();
                            }}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                          />
                        </>
                      ) : (
                        <ConfirmDialog
                          trigger={(open) => (
                            <Button onClick={open} type="button" style={{ textTransform: 'capitalize' }}>
                              Undelete
                            </Button>
                          )}
                          title="Undelete Category"
                          description="Are you sure you want to undelete this category?"
                          cancelBtnText={'Cancel'}
                          submitBtnText={'Submit'}
                          onConfirm={async (close) => {
                            await handleDelete(row);
                            close();
                          }}
                          disabled={isSubmitting}
                          loading={isSubmitting}
                        ></ConfirmDialog>
                      )}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            {data.length === 0 && (
              <StyledTableRow>
                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                  No categories records found.
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {countData !== null && countData !== 0 && !countError && (
        <FullWidthBar>
          <Typography variant="subtitle1">Total: {countData}</Typography>
          <StyledPagination
            current={Number(page) + 1 || 1}
            total={Math.ceil(countData / Defaults.LIST_PAGE_SIZE)}
            onPageChange={(page: number) => setNewPage(page)}
          />
        </FullWidthBar>
      )}
    </Container>
  );
};

const Container = styled('div')({
  padding: '20px',
  backgroundColor: '#eee',
  flex: 1,
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
});

const FullWidthBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PointerTableRow = styled(TableRow)({
  cursor: 'pointer',
});

const StyledTableRow = muiStyled(PointerTableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledPagination = styled(Pagination)({
  alignSelf: 'flex-end',
});

const StyledSearchField = styled(SearchField)({
  marginLeft: 'auto',
});

export default CategoryList;

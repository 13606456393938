import { useEffect, useContext, useState, useCallback } from 'react';

import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';

import { Typography, Card, Button, Grid, styled, Checkbox, FormControlLabel } from '@mui/material';

import { SubmitButton } from '../../../components/buttons';
import { FormContainer } from '../../../components/forms';
import { BrowseUpload, TextField } from '../../../components/inputs';
import { AlertSnackbarContext } from '../../../components/snackbars';
import { Spinner } from '../../../components/spinners';
import { Defaults, ErrorMessages } from '../../../constants';
import { CategoryUpdateDTO, useGetCategory, useUpdateCategory } from '../../../lib/category/hooks';
import useMediaUpload from '../../../lib/media/hooks/useMediaUpload';
import { TopicDto, useCreateTopic } from '../../../lib/topic/hooks/useCreateTopic';
import { checkFileSize } from '../../../lib/util/helpers';
import { Media } from '../../../types/Media';

type CategoryFormType = {
  id: string;
  title: string;
  media?: any;
  published: boolean;
  additionalTopic: TopicDto[];
  filteredTopics: TopicDto[];
};

const topicDefaultValue: TopicDto = {
  title: '',
  category: {
    title: '',
    media: null,
    published: false,
  },
  published: false,
};

const CategoryEditSchema = Yup.object().shape({
  title: Yup.string().required(ErrorMessages.REQUIRED_CATEGORY_NAME),
  media: Yup.mixed().required(ErrorMessages.REQUIRED_PHOTO),
  additionalTopic: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required(ErrorMessages.REQUIRED_TOPIC_TITLE)
        .max(Defaults.MAXIMUM_TOPIC_LENGTH, ErrorMessages.INVALID_TOPIC_LENGTH),
    }),
  ),
  filteredTopics: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required('Topic Id is required'),
      title: Yup.string()
        .required(ErrorMessages.REQUIRED_TOPIC_TITLE)
        .max(Defaults.MAXIMUM_TOPIC_LENGTH, ErrorMessages.INVALID_TOPIC_LENGTH),
    }),
  ),
});

function CategoryEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const categoryId = id ?? '';
  const getCategory = useGetCategory(categoryId);
  const updateCategory = useUpdateCategory();

  const [, setAlertSnackbar] = useContext(AlertSnackbarContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [photosErrorMsg, setPhotosErrorMsg] = useState<string>('');
  const createTopic = useCreateTopic();
  const [, setAdditionalTopicCount] = useState(0);
  const [photoMedia, setPhotoMedia] = useState<Media | null>(null);
  const { upload, error: uploadError } = useMediaUpload({
    onSuccess: (data) => {
      setPhotoMedia(data);
    },
    onError: () => {
      setAlertSnackbar({
        message: ErrorMessages.SERVER_ERROR,
        severity: 'error',
      });
      setPhotosErrorMsg(ErrorMessages.SERVER_ERROR);
    },
  });

  useEffect(() => {
    if (!uploadError) return;
    setAlertSnackbar({
      message: ErrorMessages.SERVER_ERROR,
      severity: 'error',
    });
    setPhotosErrorMsg(ErrorMessages.SERVER_ERROR);
  }, [setAlertSnackbar, uploadError]);

  useEffect(() => {
    const categoryData = getCategory.data;

    if (categoryData && categoryData.media) {
      setPhotoMedia(categoryData.media);
    } else {
      setPhotoMedia(null);
    }
  }, [getCategory.data]);

  useEffect(() => {
    setSubmitting(updateCategory.isLoading);

    let message = '';
    let severity: 'error' | 'success' | 'info' | 'warning' | undefined = 'success';

    if (!updateCategory.isLoading) {
      if (updateCategory.error) {
        message = (updateCategory.error as Error).message;
      } else if (updateCategory.data) {
        message = `${updateCategory?.variables?.params.title} saved successfully.`;
      }
    }

    if (updateCategory.error) {
      severity = 'error';
    }

    if (message !== '') {
      if (severity === 'success') {
        navigate('/cms/categories');
      }
      setAlertSnackbar({ message, severity });
    }
  }, [
    navigate,
    updateCategory.variables,
    updateCategory.data,
    updateCategory.error,
    updateCategory.isLoading,
    createTopic.data,
    createTopic.error,
    createTopic.isLoading,
    setAlertSnackbar,
  ]);

  const handlePhotoUpload = useCallback(
    async (
      event: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<CategoryFormType>>,
    ) => {
      const files = event.target.files;

      if (files && files[0]) {
        const file = files[0];
        try {
          checkFileSize(file);
          setPhotosErrorMsg('');

          // Upload photo to media table and S3
          await upload(files[0]);
        } catch (error) {
          let errorMessage;
          if (error instanceof Error) errorMessage = error.message;
          else errorMessage = ErrorMessages.SERVER_ERROR;

          setPhotosErrorMsg(errorMessage);
          setAlertSnackbar({
            message: errorMessage,
            severity: 'error',
          });
        }

        setFieldValue('media', file);
      }
    },
    [setAlertSnackbar, upload],
  );

  const handleSubmit = async (values: CategoryFormType, actions: FormikHelpers<CategoryFormType>) => {
    const categoryObj: CategoryUpdateDTO = {
      title: values.title,
      media: photoMedia,
      published: values.published === true,
      featured: initialValues.featured === null ? false : true,
    };

    const topic: TopicDto[] = values.filteredTopics.map((item: TopicDto) => {
      return {
        id: item.id,
        title: item.title,
        category: { id: categoryId, ...categoryObj },
        published: item.published,
      };
    });

    if (topic.length === 0) {
      // update and save category
      updateCategory.mutate({ categoryId: values.id, params: { ...categoryObj } });
    } else {
      // update and save category and topic
      updateCategory.mutate({ categoryId: values.id, params: { ...categoryObj, topic } });
    }

    if (values.additionalTopic.length > 0) {
      const topicArrayValues: TopicDto[] = values.additionalTopic.map((item: TopicDto) => {
        return {
          title: item.title,
          category: { id: categoryId, ...categoryObj },
          published: item.published,
        };
      });

      // save topic
      createTopic.mutate(topicArrayValues);
    }
  };

  let initialValues: any;
  let ready = false;

  if (getCategory.data) {
    initialValues = {
      ...getCategory.data,
      additionalTopic: [],
      filteredTopics: getCategory.data.topics,
      published: getCategory.data.published === null ? false : true,
    };
  }
  ready = !getCategory.isLoading;

  const setInitValue = (value?: string | boolean) => value || ' ';

  return (
    <Container>
      <TopBar>
        <Typography variant="h5">Category</Typography>
      </TopBar>

      <StyledCard>
        {!ready && <Spinner />}
        {ready && initialValues && (
          <Formik initialValues={initialValues} validationSchema={CategoryEditSchema} validateOnBlur={true} onSubmit={handleSubmit}>
            {({ values, errors, touched, setFieldValue, handleChange, handleBlur }) => (
              <FormContainer>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <TextField name="id" label="ID" value={values.id} disabled helperText=" " />
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: photoMedia ? '30px' : '0' }}>
                    <Grid item xs={8}>
                      <BrowseUpload
                        error={touched.media && !!errors.media}
                        fileLabel="Upload Photo"
                        name="media"
                        handleFileInput={(event) => {
                          handlePhotoUpload(event, setFieldValue);
                        }}
                        handleBlur={handleBlur}
                        disabled={isSubmitting}
                        errorMsg={photosErrorMsg}
                        helperText={errors.media && touched.media ? errors.media : null}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {photoMedia ? (
                        <>
                          <Typography>Uploaded Photo</Typography>
                          <img src={photoMedia?.smallUrl} alt="" />
                        </>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        error={touched.title && !!errors.title}
                        type="text"
                        name="title"
                        label="Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        helperText={setInitValue(touched.title && errors.title)}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="published"
                            name="published"
                            checked={values.published}
                            value={values.published || true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Enable"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTypography>Topics per Category</StyledTypography>
                  </Grid>
                  {values.filteredTopics &&
                    values.filteredTopics.map((topic: TopicDto, index: number) => {
                      let isPublished = values.filteredTopics?.[index]?.published ? true : false;
                      if (!values.published) isPublished = false;
                      return (
                        <Grid container spacing={2} key={`${topic}-${index}`}>
                          <Grid item xs={8}>
                            <TextField
                              type="text"
                              error={
                                touched.filteredTopics?.[index]?.title &&
                                !!(errors.filteredTopics?.[index] as FormikErrors<TopicDto>)?.title
                              }
                              name={`filteredTopics[${index}].title`}
                              value={values.filteredTopics?.[index]?.title}
                              label="Topic Title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={setInitValue(
                                touched.filteredTopics?.[index]?.title && (errors.filteredTopics?.[index] as FormikErrors<TopicDto>)?.title,
                              )}
                              disabled={isSubmitting}
                              style={{
                                paddingBottom: '20px',
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={`filteredTopics[${index}].published`}
                                  name={`filteredTopics[${index}].published`}
                                  value={isPublished}
                                  checked={isPublished}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={values.published ? false : true}
                                />
                              }
                              label="Enable"
                            />
                          </Grid>
                        </Grid>
                      );
                    })}

                  {values.additionalTopic &&
                    values.additionalTopic.map((topic: TopicDto, i: number) => {
                      return (
                        <Grid container spacing={2} key={`${topic}-${i}`}>
                          <Grid item xs={8}>
                            <TextField
                              error={
                                touched.additionalTopic?.[i]?.title && !!(errors.additionalTopic?.[i] as FormikErrors<TopicDto>)?.title
                              }
                              type="text"
                              name={`additionalTopic[${i}].title`}
                              label="Topic Title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={setInitValue(
                                touched.additionalTopic?.[i]?.title && (errors.additionalTopic?.[i] as FormikErrors<TopicDto>)?.title,
                              )}
                              disabled={isSubmitting}
                              style={{
                                paddingBottom: '10px',
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={`additionalTopic.[${i}].published`}
                                  value={topic.published}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={values.published ? false : true}
                                />
                              }
                              label="Enable"
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <StyledButton
                              onClick={() => {
                                values.additionalTopic.splice(i, 1);
                                setAdditionalTopicCount((state: number) => state - 1);
                              }}
                              type="button"
                            >
                              Remove
                            </StyledButton>
                          </Grid>
                        </Grid>
                      );
                    })}

                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => {
                        values.additionalTopic.push(topicDefaultValue);
                        setAdditionalTopicCount((state: number) => state + 1);
                      }}
                      type="button"
                    >
                      Add Topic
                    </StyledButton>
                  </Grid>
                </Grid>
                <ButtonContainer>
                  <Buttons>
                    <SubmitButton type="submit" disabled={isSubmitting} loading={isSubmitting} variant="contained">
                      Save
                    </SubmitButton>
                    <Button onClick={() => navigate(-1)} type="button">
                      Cancel
                    </Button>
                  </Buttons>
                </ButtonContainer>
              </FormContainer>
            )}
          </Formik>
        )}
      </StyledCard>
    </Container>
  );
}

const Container = styled('div')({
  padding: '20px',
  backgroundColor: '#eee',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const TopBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0',
  justifyContent: 'space-between',
});

const StyledCard = styled(Card)({
  display: 'flex',
});

const StyledTypography = styled(Typography)({
  marginBottom: '10px',
});

const ButtonContainer = styled('div')({
  marginTop: '20px',
  alignSelf: 'stretch',
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
});

const Buttons = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',

  '& > *': {
    margin: '5px',
  },
});

const StyledButton = styled(Button)({
  textTransform: 'capitalize',
});

export default CategoryEdit;

import { useMutation, useQueryClient } from 'react-query';

import { PostReport } from '../../../types/PostReport';
import Api from '../../api/api';

export const deleteReportedComment = async (reportCommentId: string) => {
  const [response, bodyOrError] = await Api.delete(`comments/${reportCommentId}/reported`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useDeleteReportedCommentById = () => {
  const queryClient = useQueryClient();

  return useMutation<PostReport, Error, string>('deleteReportedComment', deleteReportedComment, {
    onSuccess: () => {
      queryClient.invalidateQueries('reportedComments');
    },
  });
};

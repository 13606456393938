import { Route, Routes } from 'react-router';

import ReportedCommentEdit from './ReportedCommentEdit';
import ReportedCommentsList from './ReportedCommentsList';

function CommentModule() {
  return (
    <Routes>
      <Route path="" element={<ReportedCommentsList />} />
      <Route path=":id" element={<ReportedCommentEdit />} />
    </Routes>
  );
}

export default CommentModule;

import ColorConverter from 'css-filter-converter';
import Resizer from 'react-image-file-resizer';

import { Defaults, ErrorMessages } from '../../constants';
import { MediaDimension, ResizeImageOptions } from '../../types/Media';

/**
 * Run a promise-returning function and catch any exceptions.
 * Useful for centralizing error handling.
 *
 * @param cb The function to run.
 * @returns A tuple where the first element is the result of the function
 * and the second element is the error that occurred, if any.
 */
export async function catchError<Cb extends Promise<unknown>>(cb: Cb): Promise<[Awaited<Cb> | null, unknown | null]> {
  try {
    return [await cb, null];
  } catch (error) {
    return [null, error];
  }
}

/**
 * Run a fetch request and return the response and parsed JSON body.
 *
 * @param url The URL to fetch.
 * @param requestOptions The options to pass to the fetch function.
 * @returns A tuple where the first element is the response and the second element is the
 * parsed JSON body. If the fetch request fails, the response will be null, and if the
 * response body is not JSON, the body will be null.
 */
export async function getFetchResponse<Data>(url: string, requestOptions: RequestInit): Promise<[Response | null, Data | null]> {
  const [response] = await catchError(fetch(url, requestOptions));
  if (!response) return [null, null];

  const [body] = await catchError(response.json());
  return [response, body];
}

/**
 * Build a query string from a given object.
 *
 * @param data The object to turn into a query string.
 * @returns The query string.
 */
export function buildQueryString(data: Record<string, string | number | boolean>): string {
  if (!data) return '';
  const entries = Object.entries(data);
  const params = entries.map((item) => item.map((el) => encodeURIComponent(el)).join('='));
  return params.join('&');
}

/**
 * Convert a hex color to a CSS filter string.
 *
 * @param hex The hex color to convert.
 * @returns The CSS filter string.
 */
export function hexToFilter(hex: string): string {
  return ColorConverter.hexToFilter(hex)?.color || 'none';
}

export function removeUrlTrailingSlash(url = '') {
  return url.replace(/\/$/, '');
}

export function emptyStringToUndefined(value: string | undefined): string | undefined {
  return value === '' ? undefined : value;
}

export const getErrorMessage = (message: string, path: string) => ({
  message,
  path: [path],
});

export const isFieldRequired = (display: boolean, value: unknown) => !display || (typeof value === 'string' && value.length > 0);

export const fileListToImageFiles = (fileList: FileList): File[] => {
  return Array.from(fileList).filter((file) => {
    const mimeType = (file.type || '').toLowerCase();
    return mimeType.startsWith('image/');
  });
};

export function checkImageFile(resource: File | Blob) {
  const imageResourceRegex = /image\//gi;
  return !!imageResourceRegex.test(resource.type);
}

export const resizeImage = async (file: File | Blob, options: ResizeImageOptions) => {
  const { compressFormat = 'JPEG', dimensions } = options;

  const resize = (dimension: MediaDimension) =>
    new Promise<Blob>((resolve) => {
      Resizer.imageFileResizer(
        file,
        dimension.width,
        dimension.height,
        compressFormat,
        100,
        0,
        (response: any) => resolve(response as Blob),
        'blob',
      );
    });

  const files: Blob[] = [];

  for (const dimension of dimensions) {
    const newImage = await resize(dimension);
    files.push(newImage);
  }

  return files;
};

export const getFileImageHeightAndWidth = (src: string) => {
  return new Promise<{
    height: number;
    width: number;
  }>((resolve) => {
    const img = new Image();
    img.onload = function () {
      resolve({
        height: (this as HTMLImageElement).height,
        width: (this as HTMLImageElement).width,
      });
    };
    img.src = src;
  });
};

export const checkFileSize = (file: File) => {
  if (checkImageFile(file)) {
    if (file.size > Defaults.FILE_IMAGE_SIZE) {
      throw new Error(ErrorMessages.FILE_IMAGE_SIZE_LIMIT);
    }
    return true;
  }

  throw new Error(ErrorMessages.FILE_IMAGE_FORMAT);
};

export type SortDirection = 'asc' | 'desc' | undefined;

export function containsHTMLTags(content: string) {
  const htmlTagRegex = /<\/?([a-z][a-z0-9]*)\b[^>]*>/i;
  return htmlTagRegex.test(content);
}

/**
 * Convert the html contents into array
 *
 * @param htmlContent The content that has HTML tags.
 * @returns Array of html contents
 */
export function separateTagsIntoArray(htmlContent: string): string[] {
  const wrappedContent = `<div>${htmlContent}</div>`;
  const parser = new DOMParser();
  const doc = parser.parseFromString(wrappedContent, 'text/html');
  const wrapper = doc.body.firstElementChild;
  if (!wrapper) {
    return [];
  }
  return Array.from(wrapper.children).map((child) => child.outerHTML);
}

/**
 * Get the first image in html content
 *
 * @param htmlContent The content that has HTML tags.
 * @returns img tag
 */
export function getFirstImageSrc(htmlContent: string): string | null {
  const wrappedContent = `<div>${htmlContent}</div>`;
  const parser = new DOMParser();
  const doc = parser.parseFromString(wrappedContent, 'text/html');
  const firstImage = doc.querySelector('img');
  return firstImage ? firstImage.getAttribute('src') : null;
}

export function extractImageWidthValue(imgTag: string): number {
  const widthMatch = imgTag.match(/width="(\d+)"/);
  return widthMatch ? parseInt(widthMatch[1], 10) : 0;
}

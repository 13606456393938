import ReactHtmlParser from 'react-html-parser';
import { useNavigate, useParams } from 'react-router';

import { Typography, Card, styled, Grid, Button, Box } from '@mui/material';

import { ConfirmDialog } from '../../../components/dialogs';
import { useDeleteReportedCommentById, useGetReportedCommentById } from '../../../lib/comment/hooks';
import { containsHTMLTags, extractImageWidthValue, getFirstImageSrc, separateTagsIntoArray } from '../../../lib/util/helpers';

function ReportedCommentEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const reportedCommentId = id ?? '';
  const getReportedComment = useGetReportedCommentById(reportedCommentId);
  const data = getReportedComment.data;
  const deleteReportedComment = useDeleteReportedCommentById();

  const author =
    data?.comment.post.author.firstName && data?.comment.post.author.lastName
      ? `${data?.comment.post.author.firstName} ${data?.comment.post.author.lastName}`
      : data?.comment.post.author.username;
  const reporter =
    data?.reporter.firstName && data?.reporter.lastName
      ? `${data?.reporter.firstName} ${data?.reporter.lastName}`
      : data?.reporter.username;
  const dataMessage = data?.comment.message;
  const contentInArray = separateTagsIntoArray(dataMessage);

  const message = containsHTMLTags(dataMessage)
    ? contentInArray.map((content, index) =>
        content.includes('img') ? (
          <Box
            key={index}
            component="img"
            sx={{
              width: `${extractImageWidthValue(dataMessage) / 16}rem`,
            }}
            alt="Comment Image"
            src={getFirstImageSrc(content)!}
          />
        ) : (
          ReactHtmlParser(content)
        ),
      )
    : dataMessage;

  const handleDelete = async (id: string) => {
    await deleteReportedComment.mutate(id);
    navigate(-1);
  };

  return (
    <Container>
      <TopBar>
        <Typography variant="h5">Comment Detail</Typography>
      </TopBar>

      <StyledCard>
        <Grid container rowSpacing={4} mb={4}>
          <Grid item xs={12}>
            <Typography>Reported Date: {new Date(data?.created).toLocaleDateString()}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Title of Reported Article/Post: {data?.comment.post.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Post/Article Author's Name: {author}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Reporter Username: {reporter}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Reported Username: {data?.comment.user.username}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Reason: {data?.reason}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Description: {data?.description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledTypography>Comment: </StyledTypography>
            {message}
          </Grid>
        </Grid>
        <ButtonContainer>
          <Buttons>
            <Button onClick={() => navigate(-1)} type="button">
              Cancel
            </Button>
            <ConfirmDialog
              trigger={(open) => (
                <Button onClick={open} type="button" variant="contained">
                  Remove
                </Button>
              )}
              title="Delete Reported Comment"
              description="Are you sure you want to delete this reported comment?"
              cancelBtnText={'Cancel'}
              submitBtnText={'Submit'}
              onConfirm={async (close) => {
                await handleDelete(data?.id);
                close();
              }}
            ></ConfirmDialog>
          </Buttons>
        </ButtonContainer>
      </StyledCard>
    </Container>
  );
}

const Container = styled('div')({
  padding: '20px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const TopBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0',
  justifyContent: 'space-between',
});

const StyledCard = styled(Card)({
  padding: '40px',
});

const ButtonContainer = styled('div')({
  marginTop: '40px',
  alignSelf: 'flex-end',
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
});

const Buttons = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  '& > *': {
    margin: '5px',
  },
});

const StyledTypography = styled(Typography)({
  display: 'inline-block',
  marginRight: '3px',
  '> p': {
    margin: '0',
  },
});

export default ReportedCommentEdit;

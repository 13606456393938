import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { Typography, TableContainer, Table, TableRow, TableCell, TableHead, TableBody, TableSortLabel, Box, styled } from '@mui/material';
import { styled as muiStyled } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { SearchField } from '../../../components/inputs';
import { Pagination } from '../../../components/paginations';
import { Spinner } from '../../../components/spinners';
import { Defaults } from '../../../constants';
import useGetAllReportedPosts from '../../../lib/post/hooks/useGetAllReportedPosts';
import { SortDirection } from '../../../lib/util/helpers';

const ReportedPostsList = () => {
  const navigate = useNavigate();
  const [optionsUpdated, setOptionsUpdated] = useState(true);
  const handleEditReportedPost = (id: number) => navigate(`/cms/reported-posts/${id}`);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    search: StringParam,
    sort: StringParam,
  });
  const { page, search, sort } = query;
  const searchString = search && { search };
  const debouncedSearchFn = useDebouncedCallback((value: string) => {
    setQuery({ search: value, page: 0 });
  }, Defaults.SEARCH_DEBOUNCE_MS);

  const {
    data: reportedPost,
    isLoading,
    error,
  } = useGetAllReportedPosts({
    page: Number(page) || 0,
    sort: String(sort || ''),
    ...searchString,
  });

  useEffect(() => {
    if (!optionsUpdated) {
      return;
    }
    setOptionsUpdated(false);
  }, [optionsUpdated, page, sort, search]);

  useEffect(() => {
    setOptionsUpdated(true);
  }, [setOptionsUpdated, page, sort, search]);

  const setNewSort = (column: string, direction: SortDirection) => {
    const newSort = direction ? `${column} ${direction}` : undefined;
    setQuery({ sort: newSort });
  };

  const setNewPage = (page: number) => setQuery({ page: page - 1 });

  const sortToObj = (sortStr: string | undefined) => {
    const initialValues = { column: '', direction: undefined };
    if (typeof sortStr !== 'string') {
      return initialValues;
    }
    const [column, direction] = sortStr.split(' ');
    if (typeof column !== 'string' || !['asc', 'desc', undefined].includes(direction)) {
      return initialValues;
    }
    return { ...initialValues, column, direction: direction as SortDirection };
  };

  const sortObj = sortToObj(sort ?? '');

  const columns = [
    {
      text: 'Title',
      name: 'post.title',
    },
    {
      text: `Author's Name`,
      name: 'author.username',
    },
    {
      text: 'Reported Username',
      name: 'reporter.username',
    },
    {
      text: 'Date Reported',
      name: 'post_report.created',
    },
    {
      text: 'Reason',
      name: 'post_report.reason',
    },
  ];

  return (
    <Container>
      <FullWidthBar>
        <Typography variant="h5" sx={{ marginRight: '20px' }}>
          Reported Posts
        </Typography>
        <StyledSearchField
          searchString={search ?? ''}
          onSearch={(search: string) => {
            debouncedSearchFn(search);
          }}
        />
      </FullWidthBar>

      <TableContainer sx={{ backgroundColor: 'white' }}>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <PointerTableRow>
              {columns.map((column) => (
                <TableCell key={column.name}>
                  <TableSortLabel
                    active={sortObj.column === column.name}
                    direction={sortObj.direction}
                    onClick={() => {
                      const isAsc = sortObj.column === column.name && sortObj.direction === 'asc';
                      setNewSort(column.name, isAsc ? 'desc' : 'asc');
                    }}
                    component="a"
                  >
                    {column.text}
                    {sortObj.column === column.name ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sortObj.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </PointerTableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Spinner />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              !error &&
              reportedPost.data &&
              reportedPost.data.map((row: any, index: number) => {
                return (
                  <StyledTableRow key={index} onClick={() => handleEditReportedPost(row.id)}>
                    <TableCell>{row.post.title}</TableCell>
                    <TableCell>{row.post.author.username}</TableCell>
                    <TableCell>{row.reporter.username}</TableCell>
                    <TableCell>{new Date(row.created).toLocaleDateString()}</TableCell>
                    <TableCell>{row.reason}</TableCell>
                  </StyledTableRow>
                );
              })}
            {reportedPost.data.length === 0 && (
              <StyledTableRow>
                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                  No reported posts records found.
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {reportedPost.total !== null && reportedPost.total !== 0 && (
        <FullWidthBar>
          <Typography variant="subtitle1">Total: {reportedPost.total}</Typography>
          <StyledPagination
            current={Number(page) + 1 || 1}
            total={Math.ceil(reportedPost.total / Defaults.LIST_PAGE_SIZE)}
            onPageChange={(page: number) => setNewPage(page)}
          />
        </FullWidthBar>
      )}
    </Container>
  );
};

const Container = styled('div')({
  padding: '20px',
  flex: 1,
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
});

const FullWidthBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PointerTableRow = styled(TableRow)({
  cursor: 'pointer',
});

const StyledTableRow = muiStyled(PointerTableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledPagination = styled(Pagination)({
  alignSelf: 'flex-end',
});

const StyledSearchField = styled(SearchField)({
  marginLeft: 'auto',
});

export default ReportedPostsList;
